import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import { useAutoScroll } from '@/features/user/chat/hooks/useAutoScroll'

import { Chat, ChatBubble } from './ChatBubble'
import { ChatAnsweringText } from './ChatAnsweringText'
import { ChatImageBubble } from './ChatImageBubble'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
`

const Content = styled(Box)`
  max-width: 960px;
  width: 100%;
  padding: 24px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export type ChatHistoryType = Chat[]

type Props = {
  chatHistory: ChatHistoryType
  isAnswering: boolean
  isHistory?: boolean
}

export const ChatHistory = ({ chatHistory, isAnswering, isHistory = false }: Props) => {
  const { scrollRootRef, handleSetArrowAutoScroll } = useAutoScroll({ chatHistory })

  return (
    <Container ref={scrollRootRef} onScroll={handleSetArrowAutoScroll}>
      <Content>
        {chatHistory.map((chat, i) => (
          // 今回は配列の順番変わらないのでkeyにindexを使用
          <React.Fragment key={i}>
            {
              // 回答生成中などでメッセージが空だった場合は何も出力しない
              chat.message &&
                (chat.image ? (
                  <ChatImageBubble
                    src={chat.image.src}
                    title={chat.image.text}
                    name={chat.image.name}
                    isHistory={isHistory}
                  />
                ) : (
                  <ChatBubble chat={chat} isHistory={isHistory} />
                ))
            }
          </React.Fragment>
        ))}
        {isAnswering && <ChatAnsweringText />}
      </Content>
    </Container>
  )
}
