export const ChatSquareIcon = () => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6673 17.5007L8.07952 21.1333C7.57907 21.64 7.32884 21.8934 7.11376 21.9112C6.92717 21.9267 6.74447 21.8517 6.6226 21.7096C6.48213 21.5457 6.48213 21.1896 6.48213 20.4774V18.6575C6.48213 18.0186 5.9589 17.5563 5.32672 17.4637V17.4637C3.79668 17.2396 2.59506 16.038 2.37094 14.5079C2.33398 14.2556 2.33398 13.9546 2.33398 13.3525V7.93398C2.33398 5.9738 2.33398 4.99371 2.71546 4.24502C3.05102 3.58645 3.58645 3.05102 4.24502 2.71546C4.99371 2.33398 5.9738 2.33398 7.93398 2.33398H16.5673C18.5275 2.33398 19.5076 2.33398 20.2563 2.71546C20.9149 3.05102 21.4503 3.58645 21.7858 4.24502C22.1673 4.99371 22.1673 5.9738 22.1673 7.93398V12.834M22.1673 25.6673L19.6282 23.902C19.2712 23.6539 19.0928 23.5298 18.8986 23.4418C18.7262 23.3637 18.5449 23.3069 18.3588 23.2726C18.1491 23.234 17.9318 23.234 17.4971 23.234H15.4007C14.0939 23.234 13.4405 23.234 12.9413 22.9797C12.5023 22.756 12.1453 22.399 11.9216 21.96C11.6673 21.4608 11.6673 20.8074 11.6673 19.5007V16.5673C11.6673 15.2605 11.6673 14.6071 11.9216 14.108C12.1453 13.669 12.5023 13.312 12.9413 13.0883C13.4405 12.834 14.0939 12.834 15.4007 12.834H21.934C23.2408 12.834 23.8942 12.834 24.3933 13.0883C24.8323 13.312 25.1893 13.669 25.413 14.108C25.6673 14.6071 25.6673 15.2605 25.6673 16.5673V19.734C25.6673 20.8212 25.6673 21.3648 25.4897 21.7936C25.2529 22.3653 24.7986 22.8196 24.2269 23.0564C23.7981 23.234 23.2545 23.234 22.1673 23.234V25.6673Z'
        stroke='#8C0026'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
