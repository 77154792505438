import { ChangeEvent, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import { mediaQuery } from '@/utils/helpers/breakpoint'
import { fontSize, fontWeight, textColor } from '@/utils/themeConfigs/customTheme'
import { BasicModal } from '@/components/uis/Modal/BasicModal'
import { ThumbsUpIcon } from '@/components/icon/ThumbsUpIcon'
import { ThumbsDownIcon } from '@/components/icon/ThumbsDownIcon'
import { BasicButton } from '@/components/uis/Button/BasicButton'
import { BasicTextArea } from '@/components/uis/Input/BasicTextArea'
import { useAuthContext } from '@/context/AuthContext'

import { Feedback, FeedbackModalProps } from '../../../types'
import { useFeedback } from '../../../hooks/useFeedback'

const Container = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${textColor.dark};

  ${mediaQuery('tab')} {
    padding: 0;
  }
`

const Title = styled(Box)`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.lg};
  text-align: center;
`

const Description = styled(Box)`
  font-size: ${fontSize.xs};
`

const Evaluation = styled(Box)`
  display: flex;
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};
  gap: 2px;
  align-items: center;
  stroke-width: 2px;
`

const ButtonContainer = styled(Box)`
  display: flex;
  padding: 16px 0 0;
  gap: 16px;
  justify-content: center;

  ${mediaQuery('tab')} {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const FeedbackModal = ({
  modal,
  chatLogId,
  evaluation,
  onSuccess,
}: FeedbackModalProps) => {
  const { user } = useAuthContext()

  const [comment, setComment] = useState<string>('')

  const { mutate } = useFeedback(onSuccess, modal.handleClose)

  const handleChangeComment = (
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setComment(value.target.value)
  }

  const handleSubmit = () => {
    const data: Feedback = {
      evaluation: evaluation,
      comment: comment,
    }

    mutate({
      token: user?.token ?? '',
      chatLogId: chatLogId,
      data: data,
    })
  }

  const handleClose = () => {
    setComment('')
    modal.handleClose()
  }

  return (
    <BasicModal
      open={modal.isOpen}
      onClose={modal.handleClose}
      innerWidth='ml'
      wrapperWidth='ml'
    >
      <Container>
        <Title>フィードバックを送信します</Title>
        <Description>
          ※送信ボタンをクリックすると評価が反映されます。コメントは任意のため空欄でも送信が可能です。
        </Description>
        {evaluation === 1 && (
          <Evaluation>
            <ThumbsUpIcon />
            良い評価
          </Evaluation>
        )}
        {evaluation === 2 && (
          <Evaluation>
            <ThumbsDownIcon />
            悪い評価
          </Evaluation>
        )}
        <BasicTextArea
          label='コメント'
          placeholder=''
          maxRows={1}
          value={comment}
          onChange={handleChangeComment}
        />
        <ButtonContainer>
          <BasicButton
            label='キャンセル'
            width='160px'
            height='47px'
            variant='outlined'
            onClick={handleClose}
          />
          <BasicButton label='送信' width='160px' height='47px' onClick={handleSubmit} />
        </ButtonContainer>
      </Container>
    </BasicModal>
  )
}
