export const ThumbsDownIcon = ({ size = 16, color = '#A1A1A1', strokeWidth = 1 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.334 1.33398V8.66732M14.6674 6.53398V3.46732C14.6674 2.72058 14.6674 2.34721 14.522 2.062C14.3942 1.81111 14.1902 1.60714 13.9393 1.47931C13.6541 1.33398 13.2808 1.33398 12.534 1.33398H5.41269C4.43837 1.33398 3.95121 1.33398 3.55774 1.51227C3.21095 1.66941 2.91622 1.92227 2.70818 2.24113C2.47213 2.60291 2.39806 3.08441 2.2499 4.0474L1.90119 6.31407C1.70578 7.58419 1.60808 8.21926 1.79656 8.7134C1.96198 9.14711 2.27312 9.50978 2.67663 9.73923C3.13637 10.0007 3.77891 10.0007 5.06398 10.0007H5.60069C5.97405 10.0007 6.16074 10.0007 6.30335 10.0733C6.42879 10.1372 6.53078 10.2392 6.59469 10.3647C6.66735 10.5073 6.66735 10.6939 6.66735 11.0673V13.0234C6.66735 13.9313 7.40335 14.6673 8.31125 14.6673C8.5278 14.6673 8.72403 14.5398 8.81198 14.3419L11.0525 9.30077C11.1544 9.07148 11.2053 8.95684 11.2859 8.87278C11.3571 8.79847 11.4445 8.74165 11.5413 8.70678C11.6508 8.66732 11.7763 8.66732 12.0272 8.66732H12.534C13.2808 8.66732 13.6541 8.66732 13.9393 8.52199C14.1902 8.39416 14.3942 8.19019 14.522 7.93931C14.6674 7.65409 14.6674 7.28072 14.6674 6.53398Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
