export const ThumbsUpIcon = ({ size = 16, color = '#A1A1A1', strokeWidth = 1 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.66732 14.6673V7.33398M1.33398 8.66732V13.334C1.33398 14.0704 1.93094 14.6673 2.66732 14.6673H11.6181C12.6053 14.6673 13.4448 13.9471 13.5949 12.9714L14.3128 8.30477C14.4992 7.09325 13.5619 6.00065 12.3361 6.00065H10.0007C9.63246 6.00065 9.33398 5.70217 9.33398 5.33398V2.97788C9.33398 2.06998 8.59799 1.33398 7.69009 1.33398C7.47354 1.33398 7.2773 1.46151 7.18936 1.6594L4.84328 6.93808C4.73628 7.17883 4.49753 7.33398 4.23407 7.33398H2.66732C1.93094 7.33398 1.33398 7.93094 1.33398 8.66732Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
