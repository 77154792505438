import { fontSize, textColor } from '@/utils/themeConfigs/customTheme'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'

const Description = styled(Typography)`
  font-size: ${fontSize.xxs};
  color: ${textColor.mediumGray};
  margin-bottom: 4px;
`

type BasicDescriptionProps = {
  description: string
}
export const BasicDescription = ({ description }: BasicDescriptionProps) => {
  return <Description>{description}</Description>
}
