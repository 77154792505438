import styled from '@emotion/styled'
import { textColor, fontSize, functionalColor } from '@/utils/themeConfigs/customTheme'

const Flex = styled.div`
  display: flex;
  gap: 4px;
`

const Label = styled('label', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{
  disabled: boolean
}>`
  display: block;
  color: ${({ disabled }) => (disabled ? textColor.lightGray : textColor.dark)};
  font-size: ${fontSize.xxs};
  font-weight: 700;
  margin-bottom: 4px;
`

const Required = styled.div`
  height: fit-content;
  border-radius: 2px;
  padding: 0 2px;
  background-color: ${functionalColor.red};
  color: ${textColor.white};
  font-size: ${fontSize.xxxs};
`

type BasicLabelProps = {
  label?: string
  htmlFor?: string
  isRequired?: boolean
  disabled?: boolean
}

export const BasicLabel = ({
  label,
  htmlFor,
  isRequired = false,
  disabled = false,
}: BasicLabelProps) => {
  return (
    <Flex>
      <Label htmlFor={htmlFor} disabled={disabled}>
        {label}
      </Label>
      {isRequired && <Required>必須</Required>}
    </Flex>
  )
}
