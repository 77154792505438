import styled from '@emotion/styled'
import { TextField, TextFieldProps } from '@mui/material'

import {
  bgColor,
  outlineColor,
  functionalColor,
  fontSize,
  textColor,
} from '@/utils/themeConfigs/customTheme'
import { BasicLabel } from '@/components/uis/Input/BasicLabel'
import { BasicDescription } from './BasicDescription'

const Wrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
`
const ErrorMessage = styled.div`
  color: ${functionalColor.red};
  font-size: ${fontSize.xxs};
  margin-top: 2px;
`

const CustomMuiTextArea = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'minHeight' && prop !== 'hasError',
})<{ minHeight: string; hasError?: boolean }>`
  width: 100%;

  .MuiOutlinedInput-root {
    background: ${bgColor.white};
    border-radius: 6px;
    border: 1px solid
      ${({ hasError }) => (hasError ? functionalColor.red : outlineColor.lightGray)};
    padding: 10px 12px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  textarea {
    min-height: ${({ minHeight }) => minHeight};
    &.Mui-disabled {
      -webkit-text-fill-color: ${textColor.dark};
    }
  }
`

export type TextAreaProps = {
  id?: string
  placeholder: string
  description?: string
  minHeight?: string
  errors?: boolean
  label?: string
  width?: string
  errorMessage?: string
  isRequired?: boolean
}

export const BasicTextArea = ({
  id,
  placeholder,
  description,
  maxRows = 2,
  minHeight = '120px',
  errors,
  label,
  width,
  errorMessage,
  value,
  isRequired = false,
  ...props
}: TextAreaProps & TextFieldProps) => {
  return (
    <Wrapper width={width}>
      {label && <BasicLabel label={label} htmlFor={id} isRequired={isRequired} />}
      {description && <BasicDescription description={description} />}
      <CustomMuiTextArea
        id={id}
        placeholder={placeholder}
        minHeight={minHeight}
        maxRows={maxRows}
        multiline
        hasError={!!errors}
        value={value ?? ''}
        {...props}
      />
      {errors && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  )
}
