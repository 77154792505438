import { useMutation } from '@tanstack/react-query'

import { MutationKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'

import { Feedback } from '../types'

export const useFeedback = (onSuccess: () => void, handleClose: () => void) => {
  const { apiClient } = useApiClient()

  return useMutation({
    mutationKey: MutationKeys.feedback,
    mutationFn: async ({
      token,
      chatLogId,
      data,
    }: {
      token: string
      chatLogId: string
      data: Feedback
    }) => await apiClient(token).post(API.feedback(chatLogId, data)),
    onSuccess: () => {
      onSuccess(), handleClose()
    },
    onError: () => {},
  })
}
